import { gql } from '@apollo/client';

const GET_EEZY_PAY_VAT_OPTIONS = gql`
    query GetEezyPayVatOptions {
        eezyPayVatOptions {
            value
            default
        }
    }
`;

export default GET_EEZY_PAY_VAT_OPTIONS;
