import { EezyButton } from 'components/Buttons';
import { Icon } from 'components/Icon';
import { ValidationMessageBlock } from 'components/ValidationMessageBlock';
import { useTranslation } from 'react-i18next';
import { COLOR_BLACK, COLOR_WARINING_BACKGROUND } from 'styles/variables';
import { Link } from 'react-router-dom';

export function CostDocumentContractInfo({
    hasContract,
    isLoadingContract,
    relatedObject,
}: {
    hasContract?: boolean;
    isLoadingContract?: boolean;
    relatedObject?: any;
}) {
    const { t, i18n } = useTranslation();

    const urlReadMore =
        i18n.language === 'fi'
            ? 'https://kevytyrittajat.eezy.fi/kevytyrittajyys/nain-kevytyrittaminen-toimii/lisapalvelut/kulukorvaukset-ja-kuitit/'
            : 'https://kevytyrittajat.eezy.fi/en/light-entrepreneurship/this-is-how-our-service-works/additional-services/expense-reimbursements-and-receipts/';

    const urlDownload =
        i18n.language === 'fi'
            ? 'https://kevytyrittajat.eezy.fi/wp-content/uploads/2025/01/SOPIMUS-TAVARAHANKINTOJEN-MAKSAMISESTA-suomi.pdf'
            : 'https://kevytyrittajat.eezy.fi/wp-content/uploads/2025/01/SOPIMUS-TAVARAHANKINTOJEN-MAKSAMISESTA-eng.pdf';

    if (isLoadingContract) {
        return null;
    }

    if (hasContract) {
        return (
            <div className="bg-green-50 w-fit py-3 px-4 rounded-lg flex items-center mb-4 mt-4">
                <Icon icon={['far', 'circle-check']} className="mr-2" color="#326F65" />
                <span className="text-green-900 text-sm">{t('costs.contract-missing.contractFound')}</span>
            </div>
        );
    }

    return (
        <ValidationMessageBlock
            iconColor={COLOR_BLACK}
            type={'warning'}
            style={{
                backgroundColor: COLOR_WARINING_BACKGROUND,
                borderRadius: 4,
                padding: 10,
            }}
            title={t('costs.contract-missing.missing')}
        >
            <div className="flex flex-col gap-2">
                <div className="m-1 ml-0">
                    <span>{t('costs.contract-missing.1')}</span>
                    <span className="ml-1">{t('costs.contract-missing.2')}</span>
                    <span className="ml-1">{t('costs.contract-missing.3')}</span>
                </div>
                <div className="m-1 ml-0">
                    <span>{t('costs.contract-missing.4')}</span>
                    <span className="ml-1">{t('costs.contract-missing.5')}</span>
                </div>
                <div className="self-end flex gap-2 w-full flex-wrap">
                    <a href={urlReadMore} target="_blank" rel="noreferrer">
                        <EezyButton color="black" className="v2-btn">
                            {t('costs.contract-missing.readMore')}
                            <Icon icon={['far', 'arrow-up-right-from-square']} className="ml-2" />
                        </EezyButton>
                    </a>

                    <a href={urlDownload} target="_blank" rel="noreferrer">
                        <EezyButton color="black" className="v2-btn">
                            <Icon icon={['far', 'arrow-down-to-line']} className="mr-2" />
                            {t('costs.contract-missing.downloadTemplate')}
                        </EezyButton>
                    </a>

                    <Link
                        to={{
                            pathname: '/support/new-message',
                        }}
                        state={{ relatedObject: relatedObject }}
                    >
                        <EezyButton color="black" className="v2-btn">
                            <Icon icon={['far', 'envelope']} className="mr-2" />
                            {t('costs.contract-missing.sendContract')}
                        </EezyButton>
                    </Link>
                </div>
            </div>
        </ValidationMessageBlock>
    );
}
